import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { variables } from 'environments/environment-variables';
import { Observable } from 'rxjs';
import { User } from '../../models/user';
import { HeaderService } from '../header/header.service';
import { Publication } from '../../models/publication';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private headerService: HeaderService) {

  }

  public create(user: User): Observable<any> {
    return this.http.post(`${variables.baseURL}/users/create`,
      user, { headers: this.headerService.header() });
  }

  public update(user: User): Observable<any> {
    return this.http.put(`${variables.baseURL}/users/update`,
      user, { headers: this.headerService.header() });
  }

  public updateUser(id: number, user: User): Observable<any> {
    return this.http.put(`${variables.baseURL}/user/update/${id}`,
      user, { headers: this.headerService.header() });
  }


  /**
   * Retourne un utilisateur à partir de son oid
   * @param oid identifiant d'utilisateur retourné dans le token par l'azure ad b2c
   * @param skipError 
   * @returns 
   */
  public getByOid(skipError: boolean = false): Observable<any> {
    return this.http.get(`${variables.baseURL}/users/me`,
      { headers: (skipError) ? this.headerService.headerSkipError() : this.headerService.header() });
  }

  public getById(id: number): Observable<any> {
    return this.http.get(`${variables.baseURL}/users/${id}`,
      { headers: this.headerService.header() });
  }

  public search(username: string, pageNumber: number, pageSize: number): Observable<any> {
    return this.http.get(`${variables.baseURL}/users/search/${username}/${pageNumber}/${pageSize}`,
      { headers: this.headerService.header() });
  }

  public getByUsername(username: string): Observable<any> {
    return this.http.get(`${variables.baseURL}/get-user/by-username?username=${username}`,
      { headers: this.headerService.header() });
  }

  public updateProfilePicture(useroid: string, path: string): Observable<any> {
    return this.http.put(`${variables.baseURL}/user/update-profile-picture?useroid=${useroid}&path=${path}`,
      { headers: this.headerService.header() });
  }

  deactivateUserAccount(userId: number, isTemporary: boolean): Observable<any> {
    const url = `${variables.baseURL}/users/${userId}/deactivate`; // URL pour la désactivation
    const data = { isTemporary: isTemporary };
    return this.http.post<any>(url, data);
  }

  reactivateUserAccount(userId: number): Observable<any> {
    const url = `${variables.baseURL}/users/${userId}/reactivate`; // URL pour la réactivation
    return this.http.post<any>(url, {});
  }

  deactivationDuration: number;

  isUserActive(user: User): boolean {
    if (user.deactivationDuration == null) {
      return true; // L'utilisateur est actif
    } else if (user.deactivationDuration === 0) {
      return false; // Désactivation permanente
    } else {
      return new Date() < user.deactivationDate; // Désactivation temporaire

    }
  }

  getTotalLikes(userId: number): Observable<number> {
    const url = `${variables.baseURL}/users/total-likes/${userId}`;
    return this.http.get<number>(url);
  }

  register(user: User) {
    return this.http.post(`${variables.baseURL}/user/create`, user, { headers: this.headerService.header() });
  }

  public updateStatus(id: number, newStatus: boolean): Observable<any> {
    return this.http.put(`${variables.baseURL}/users/update-status?id=${id}&newStatus=${newStatus}`, {
      headers: this.headerService.header()
    });
  }
  
  public getMostLikedPublication(): Observable<Publication> {
    return this.http.get<Publication>(`${variables.baseURL}/publications/mostliked`, {
      headers: this.headerService.header()
    });
  }

  public getTopLikedPublications(): Observable<Publication[]> {
    return this.http.get<Publication[]>(`${variables.baseURL}/publications/top-liked`, 
      { headers: this.headerService.header() });
  }


}
