import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, provideRouter, withComponentInputBinding } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard/auth-guard.service';

const routes: Routes = [
  
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'callback',
    loadChildren: () => import('./pages/callback/callback.module').then(m => m.CallbackPageModule)
  },
  {
    path: ':publicationId/comments',
    loadChildren: () => import('./pages/comment-list/comment-list.module').then(m => m.CommentListPageModule)
  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./pages/profil/profil.module').then(m => m.ProfilPageModule), canActivate: [AuthGuard], canDeactivate: [AuthGuard]
  },
  {
    path: 'search-user',
    loadChildren: () => import('./pages/search-user/search-user.module').then(m => m.SearchUserPageModule), canActivate: [AuthGuard], canDeactivate: [AuthGuard]
  },
  {
    path: 'fil-actualite',
    loadChildren: () => import('./pages/fil-actualite/fil-actualite.module').then(m => m.FilActualitePageModule)
  },
  {
    path: 'conversation/:username',
    loadChildren: () => import('./pages/conversation/conversation.module').then( m => m.ConversationPageModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'notifications/:username',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'parametrage-confidentialite',
    loadChildren: () => import('./pages/parametrage-confidentialite/parametrage-confidentialite.module').then(m => m.ParametrageConfidentialitePageModule)
  },
  {
    path: 'signalement',
    loadChildren: () => import('./pages/signalement/signalement.module').then( m => m.SignalementPageModule)
  },
  {
    path: 'groupe',
    loadChildren: () => import('./pages/groupe/groupe.module').then( m => m.GroupePageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'groupeinfo/:selectedUsernames',
    loadChildren: () => import('./pages/groupeinfo/groupeinfo.module').then( m => m.GroupeinfoPageModule)
  },
  {
    path: 'groupeupdate/:groupname',
    loadChildren: () => import('./pages/groupeupdate/groupeupdate.module').then( m => m.GroupeupdatePageModule)
  },
  {
    path: 'groupeconversation/:groupname',
    loadChildren: () => import('./pages/groupeconversation/groupeconversation.module').then( m => m.GroupeconversationPageModule)
  },
  {
    path: 'contenugroupe/:groupname',
    loadChildren: () => import('./pages/contenugroupe/contenugroupe.module').then( m => m.ContenugroupePageModule)
  },
  {
    path: 'bloquermembre/:groupname',
    loadChildren: () => import('./pages/bloquermembre/bloquermembre.module').then( m => m.BloquermembrePageModule)
  },
  {
    path: 'publication',
    loadChildren: () => import('./pages/publication/publication.module').then( m => m.PublicationPageModule)
  },
  {
    path: 'recette',
    loadChildren: () => import('./pages/recette/recette.module').then( m => m.RecettePageModule)
  },
  {
    path: 'my-shop',
    loadChildren: () => import('./pages/my-shop/my-shop.module').then( m => m.MyShopPageModule)
  },
  {
    path: 'panier',
    loadChildren: () => import('./pages/panier/panier.module').then( m => m.PanierPageModule)
  },
  {
    path: 'panier-validation',
    loadChildren: () => import('./pages/panier-validation/panier-validation.module').then( m => m.PanierValidationPageModule)
  },
  {
    path: 'membre-groupe',
    loadChildren: () => import('./pages/membre-groupe/membre-groupe.module').then( m => m.MembreGroupePageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./pages/image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },


 
  
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [
    provideRouter(routes, withComponentInputBinding())
  ]
})
export class AppRoutingModule { }
