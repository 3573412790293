<ion-header>
  <ion-toolbar>
    <ion-title>Bienvenue {{decoded.name}} !</ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="mainForm.invalid" (click)="confirm()" [strong]="true">Valider</ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-card-subtitle class="ion-padding">Veuillez renseigner quelques informations supplémentaires afin de valider la
    création de votre
    compte</ion-card-subtitle>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="mainForm" (keyup.enter)="confirm()">

    <ion-text>Champs obligatoires *</ion-text>

    <ion-item>
      <ion-toggle labelPlacement="stacked" formControlName="isPrivate">
        Profil privé *
      </ion-toggle>
    </ion-item>

    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Nom utilisateur *" placeholder=""
        formControlName="username" [required]="true"></ion-input>
    </ion-item>

    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Nom de famille *" placeholder="Nom de famille"
        formControlName="name" [required]="true"></ion-input>
    </ion-item>

    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Prénom *" placeholder="Prénom" formControlName="firstName"
        [required]="true"></ion-input>
    </ion-item>

    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Code postal" placeholder="21000"
        formControlName="cp"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Ville / Commune" placeholder="Dijon"
        formControlName="city"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="text" labelPlacement="stacked" label="Rue" placeholder="9, Rue des Corroyeurs"
        formControlName="road"></ion-input>
    </ion-item>

    <ion-item>
      <ion-input type="tel" labelPlacement="stacked" label="Téléphone mobile" placeholder="06 00 00 00 00"
        formControlName="mobilePhone"></ion-input>
    </ion-item>
    <ion-item>
      <ion-input type="tel" labelPlacement="stacked" label="Téléphone fixe" placeholder="03 00 00 00 00"
        formControlName="phone"></ion-input>
    </ion-item>

    <ion-item>
      <ion-textarea type="text" labelPlacement="stacked" label="Description" placeholder="Description de votre profil"
        formControlName="description" [counter]="true" maxlength="255" [autoGrow]="true"></ion-textarea>
    </ion-item>

    <div *ngIf="isProfessionnel()">
      <ion-item>
        <ion-input type="text" labelPlacement="stacked" label="N°SIREN *" placeholder="362 521 879"
          formControlName="siren" [required]="true"></ion-input>
      </ion-item>
      <ion-item>
        <ion-input type="text" labelPlacement="stacked" label="Métier *" placeholder="Restaurateur"
          formControlName="job" [required]="true"></ion-input>
      </ion-item>
    </div>
  </form>
</ion-content>