import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { B2cService } from 'src/app/core/services/b2c/b2c.service';
import { SharedModule } from '../../modules/shared-module/shared-module.module';
@Component({
  selector: 'app-sisu-modal',
  templateUrl: './sisu-modal.component.html',
  styleUrls: ['./sisu-modal.component.scss'],
  standalone: true,
  imports: [...SharedModule.modules]
})
export class SisuModalComponent implements OnInit {

  constructor(private b2cService: B2cService, private modalCtrl: ModalController, private navCtrl: NavController) { }

  ngOnInit() { }

  async confirm(): Promise<boolean | void> {
    this.navCtrl.navigateForward(`/sign-up`);
    await this.modalCtrl.dismiss();
  }

  /**
   * Fermer le modal / annuler les modifications
   * @returns 
   */
  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
}
