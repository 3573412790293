import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { DeviceService } from './core/device-api/device/device.service';
import { AuthGuard } from './core/services/auth-guard/auth-guard.service';
import { B2cService } from './core/services/b2c/b2c.service';
import { PreferencesEnum } from './core/enums/preferences.enum';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private b2cService: B2cService, public authGuard: AuthGuard, private deviceService: DeviceService) { }

  async ngOnInit(): Promise<void> {
    
  }
}