import { Injectable } from '@angular/core';
import { Camera, GalleryImageOptions, GalleryPhotos } from '@capacitor/camera';
import { Device, DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { ToastMessageService } from '../toastMessage/toastMessage.service';
import { Capacitor } from '@capacitor/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceInfo: DeviceInfo;

  constructor(private toastService: ToastMessageService) {
    Device.getInfo().then((d: DeviceInfo) => { this.deviceInfo = d });
  }

  checkPermissions(): Promise<PermissionStatus> {
    return Camera.checkPermissions()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * Méthode de récupération des images depuis la galerie
   * @param quality 
   * @param width 
   * @param height 
   * @returns 
   */
  public async pickImages(quality: number = 80, width: number = 800, height: number = 350): Promise<GalleryPhotos> {

    const hasPermission = await Camera.checkPermissions();

    if (hasPermission.photos) {
      // Vous avez déjà la permission, accédez à la galerie
      return this.openGallery(quality, width, height);
    } else {
      // Demandez la permission d'accéder à la galerie
      const permissionResult = await Camera.requestPermissions();

      if (permissionResult.photos && permissionResult.photos === 'granted') {
        // La permission a été accordée, accédez à la galerie
        return this.openGallery(quality, width, height);
      }
      else {
        // La permission a été refusée
        this.toastService.createToast("Vous devez autoriser l'accès à la gallerie pour pouvoir faire une publication", "error");
        throw new Error("La permission d'accès à la galerie a été refusée.");
      }
    }
  }


  public async pickVideos(): Promise<any[]> {
    try {
      const result = await FilePicker.pickFiles({
      });
      console.log("Résultat import photos/vidéos", result);
      return result.files.map(file => ({
        webPath: file.path, // Assurez-vous d'inclure le chemin web
        format: file.mimeType
      }));
    } catch (error) {
      console.error('Erreur lors de la sélection des médias :', error);
      this.toastService.createToast('Erreur lors de la sélection des médias', 'error');
      return [];
    }
  }
  

  private openGallery(quality: number, width: number, height: number): Promise<GalleryPhotos> {
    let options: GalleryImageOptions = {
      quality: quality,
      width: width,
      height: height,
      correctOrientation: true
    }

    if (this.isIos()) {
      options.presentationStyle = 'popover',
        options.limit = 10
    }

    return Camera.pickImages(options);
  }

  isAndroid(): boolean {
    return this.deviceInfo.platform === "android";
  }

  isIos(): boolean {
    return this.deviceInfo.platform === "ios";
  }

  isMobile(): boolean {
    return this.isAndroid() || this.isIos();
  }

  /**
   * Sauvegarde persistent de valeurs
   * @param name 
   * @param value 
   */
  async setProperty(name: string, value: string): Promise<void> {
    await Preferences.set({
      key: name,
      value: value
    });
  }

  async getProperty(name: string): Promise<any> {
    try {
      return (await Preferences.get({ key: name }))?.value;
    }
    catch (e) {
      return null;
    }
  };

  async getObj(name: string): Promise<any> {
    try {
      return JSON.parse((await Preferences.get({ key: name }))?.value);
    }
    catch (e) {
      return null;
    }
  };

  async setObj(name: string, value: any): Promise<any> {
    await Preferences.set({
      key: name,
      value: JSON.stringify(value)
    });
  };

  async removeProperty(name: string): Promise<void> {
    return await Preferences.remove({ key: name });
  };
}