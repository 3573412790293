import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  constructor() { }

  /** Appels API GOUV*/
  headerApiGouv(): HttpHeaders {
    return new HttpHeaders({
      "Terroir-Api-Gouv": ""
    });
  }

  /** Appels API sans authentification */
  headerSkipAuthorization(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Terroir-Skip-Authorization": ""
    });
  }

  /** Appels API avec authentification */
  header(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*"
    });
  }

  /** Appels API avec authentification */
  headerSkipError(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Terroir-Skip-Error": ""
    });
  }

  /** Appels API fichiers */
  formDataHeader(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*"
    });
  }
}