import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, switchMap, tap } from "rxjs";
import { DeviceService } from "../device-api/device/device.service";
import { ToastMessageService } from "../device-api/toastMessage/toastMessage.service";
import { InterceptorEnum } from "../enums/interceptor.enum";
import { PreferencesEnum } from "../enums/preferences.enum";
import { AuthGuard } from "../services/auth-guard/auth-guard.service";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

    constructor(private deviceService: DeviceService, private toastService: ToastMessageService, private authGuard: AuthGuard) { }

    /**
     * On intercepte toutes les requetes pour ajouter le token
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.deviceService.getProperty(PreferencesEnum.token)).pipe(
            switchMap((token) => {

                let headersConfig = new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'ConsistencyLevel': 'eventual'
                });

                if (req.headers.get('Content-Type') == "multipart/form-data") {
                    headersConfig = headersConfig.delete('Content-Type').set('Accept', '*/*');
                }

                /**
                 * Si la route n'a pas besoin de token
                 */
                if (req.headers.has(InterceptorEnum.skipAuthorization)) {
                    return this.handleRequest(req, next);
                } else if (req.headers.has(InterceptorEnum.apiGouv)) {
                    const headerGouv = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    });

                    const request = req.clone({ headers: headerGouv });
                    return this.handleRequest(request, next);

                }
                const request = req.clone({ headers: headersConfig });
                return this.handleRequest(request, next);
            })
        );
    }


    handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            tap({
                next: () => { },
                error: async (error: any) => {
                    if (error instanceof HttpErrorResponse) {
                        try {
                            switch (error.status) {
                                case 401:
                                    await this.authGuard.askSisu();
                                    break;
                                case 403:
                                    this.toastService.createToast("Vous n'êtes pas autorisés à effectuer cette action", "error");
                                    break;
                                default:
                                    // Si les erreurs ne sont pas gérée globalement
                                    if (!request.headers.has(InterceptorEnum.skipError)) {
                                        this.toastService.createToast(error.error.error, "error");
                                    }
                                    break;
                            }
                        }
                        catch (e: any) {
                            this.toastService.createToast("Une erreur est survenue, merci de rééssayer plus tard", "error");
                        }
                    }
                }
            })
        );
    }
}
