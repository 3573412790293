import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { RolesEnum } from 'src/app/core/enums/roles.enum';
import { JwtToken } from 'src/app/core/models/jwtToken';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-register-user-modal',
  templateUrl: './register-user-modal.component.html',
  styleUrls: ['./register-user-modal.component.scss'],
})
export class RegisterUserModalComponent implements OnInit {

  mainForm: FormGroup;
  decoded: JwtToken;

  constructor(private modalCtrl: ModalController, private fb: FormBuilder) { }

  ngOnInit() {
    this.mainForm = this.fb.group({
      username: [{ value: this.decoded.name, disabled: true }, Validators.required],
      mobilePhone: [""],
      phone: [""],
      name: ["", Validators.required],
      firstName: ["", Validators.required],
      description: [""],
      road: [""],
      city: [""],
      cp: [""],
      isPrivate: [this.isProfessionnel() ? false : true, Validators.required],
      siren: [""],
      job: [""]
    })
  }

  confirm(): void | Promise<boolean> {

    if (this.mainForm.valid) {
      let user: User = this.mainForm.value;
      user.username = this.mainForm?.get('username')?.value;
      user.email = this.decoded.emails[0];
      return this.modalCtrl.dismiss(user, 'confirm');
    }
  }

  isProfessionnel(): boolean {
    return this.decoded.extension_TerroirRoles == RolesEnum.Pro;
  }
}