import { Entity } from "./entity";
import { Establishment } from "./establishment";
import { Message } from "./message";

export class User extends Entity {

    oid: string;

    username: string;
    email: string;
    mobilePhone: string;
    phone: string;
    name: string;
    firstname: string;
    registerDate: Date = new Date();
    description: string;
    road: string;
    city: string;
    cp: string;
    isPrivate: boolean = false;
    profilePicture: string;
    isActive: boolean = true;
    deactivationDate: Date;
    deactivationDuration: number;
    password: string;
    followersCount: number = 0;
    followingsCount: number = 0;
    isFollowed: boolean = false;
    isFollower: boolean = false;
    acceptTerms: true;
    establishments: Establishment[];
    confirmPassword: string;
    typeProfile: string;
    latitude: number | null = null;
    longitude: number | null = null;
    /**
     * Attributs pour les professionnels
     */
    siren: string;
    job: string;
    lastmessage: string;
    lastmessageId: number;
    lastmedia: number;
    // timestampLastMessage: string;
    timestampLastMessage: any;
    // timestampLastMessage?: any;
    lastseen: { name: string, seen: boolean }[];
    status: boolean;
    constructor(id?: number, email?: string, username?: string, profilePicture?: string) {
        super();
        this.id = id;
        this.email = email;
        this.username = username;
        this.profilePicture = profilePicture;
    }
}