import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { SisuModalComponent } from 'src/app/shared/modals/sisu-modal/sisu-modal.component';
import { DeviceService } from '../../device-api/device/device.service';
import { PreferencesEnum } from '../../enums/preferences.enum';
import { RolesEnum } from '../../enums/roles.enum';
import { JwtToken } from '../../models/jwtToken';
import { User } from '../../models/user';
import { HttpClient } from '@angular/common/http';
import { variables } from 'environments/environment-variables';
import { HeaderService } from '../header/header.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  validUser: User;
  private user = new BehaviorSubject<User>(null);
  private role = new BehaviorSubject<string>(null);

  constructor(private navCtrl: NavController, private deviceService: DeviceService, private modalCtrl: ModalController, private httpClient: HttpClient, private headerService: HeaderService, private router: Router) { }

  async canActivate(): Promise<boolean> {
    if (this.validUser) {
      return true;
    } else {
      if(!this.router.url.includes('/sign-up')){
        this.askSisu();
      }
      return false; // L'utilisateur est null, donc l'accès est refusé
    }
  }

  canDeactivate(): boolean {
    return true;
  }

  async askSisu(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SisuModalComponent,
      cssClass: ["sisu-modal"]
    });
    modal.present();
  }

  isProfessionnel(): boolean {
    return this.role.getValue() == RolesEnum.Pro;
  }

  isParticulier(): boolean {
    return this.role.getValue() == RolesEnum.Pro;
  }

  setRole(role: string): void {
    this.role.next(role);
  }

  /**
   * Retourne si un utilisateur est authentifié ou non
   * @returns 
   */
  async isAuthenticated(user: User): Promise<boolean> {

    let token: string = await this.deviceService.getProperty(PreferencesEnum.token);

    let decoded: JwtToken;
    this.user.next(user);

    if (token && token != "null") {
      decoded = jwtDecode(token);
      console.log(token);
    }
    // L'utilisateur n'est pas authentifié s'il n'a pas de token ou si ce dernier est expiré
    return (token && (new Date(decoded?.exp * 1000) > new Date()))
  }

  /**
   * Récupération de l'utilisateur actuellement connecté
   * @returns 
   */
  getUser(): User {
    return this.user.getValue() ?? new User();
  }

  async setIsLoginning(isLoginning: boolean): Promise<void> {
    await this.deviceService.setObj(PreferencesEnum.isLoginning, isLoginning);
  }

  async getIsLoginning(): Promise<boolean> {
    return await this.deviceService.getObj(PreferencesEnum.isLoginning);
  }
  
  authenticate(emailorusername: string, password: string): Observable<any> {
    return this.httpClient.post(`${variables.baseURL}/user/check-credentials?emailorusername=${emailorusername}&password=${password}`, {
      headers: this.headerService.header()
    }).pipe(
      tap((response: any) => {
        // Stocker la réponse dans la variable response
        this.user.next(response);
        this.validUser = response;
        console.log(this.user);
      })
    );
  }
  

  setUser(user: User) {
    this.user.next(user);
  }

  navigateFilActualite() {
    this.navCtrl.navigateForward(`/fil-actualite`);
  }

  navigateSignIn(){
    this.navCtrl.navigateForward(`/sign-in`);
  }

  register(user: User) {
    return this.httpClient.post(`${variables.baseURL}/user/create`, user, { headers: this.headerService.header() });
  }
  
  logout(){
    this.user = new BehaviorSubject<User>(null);
    this.validUser = null;
    this.navCtrl.navigateForward('/sign-up');
  }

}