import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor() { }

  async openSite(url: string): Promise<void> {
    await Browser.open({ url: url });
  }
}
