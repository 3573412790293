import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class ToastMessageService {
    constructor(private toastController: ToastController) { }

    async createToast(libelle: string, type: "error" | "success" = "success") {
        let col: string;
        if (type == 'success') {
            col = "primary";
        }
        else if (type == 'error') {
            col = 'secondary';
        }

        const toast = await this.toastController.create({
            color: col,
            message: libelle,
            duration: 1500
        });

        await toast.present();
    }
}