<ion-content>
  <ion-toolbar class="popup-modal-toolbar">
    <ion-title>Compte requis</ion-title>
  </ion-toolbar>

  <ion-grid class="ion-text-center">

    <ion-row class="ion-padding">
      <ion-text>
        Pour accèder aux autres fonctionnalités de l'application un compte est requis, voulez vous vous connecter ou
        créer un compte ?
      </ion-text>
    </ion-row>


    <ion-row class="ion-justify-content-evenly">
      <ion-button class="button" color="primary" (click)="confirm()">
        Oui
      </ion-button>
      <ion-button class="button" color="secondary" (click)="cancel()">
        Non
      </ion-button>
    </ion-row>
  </ion-grid>

</ion-content>