import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { ModalController, NavController } from "@ionic/angular";
import { variables } from 'environments/environment-variables';
import jwtDecode from "jwt-decode";
import { RegisterUserModalComponent } from "src/app/shared/modals/register-user-modal/register-user-modal.component";
import { BrowserService } from "../../device-api/browser/browser.service";
import { DeviceService } from "../../device-api/device/device.service";
import { ToastMessageService } from "../../device-api/toastMessage/toastMessage.service";
import { PreferencesEnum } from "../../enums/preferences.enum";
import { JwtToken } from "../../models/jwtToken";
import { User } from "../../models/user";
import { AuthGuard } from "../auth-guard/auth-guard.service";
import { UserService } from "../user/user.service";

@Injectable({
    providedIn: 'root'
})
export class B2cService {

    constructor(private deviceService: DeviceService, private authGuard: AuthGuard, private navCtrl: NavController,
        private browserService: BrowserService, private userService: UserService,
        private modalCtrl: ModalController, private toastService: ToastMessageService) { }

    async login(): Promise<void> {

        await this.authGuard.setIsLoginning(true);

        let redirectUrl: string;

        // Si on est sur une version mobile on ouvre l'application
        if (this.deviceService.isMobile()) {
            redirectUrl = `${variables.bc2Url}&redirect_uri=${variables.b2cRedirectUriAndroid}`;
        }
        // Si on est sur une version web on rédirige sur /callback
        else {
            redirectUrl = `${variables.bc2Url}&redirect_uri=${variables.b2cRedirectUriWeb}`;
        }

        // On ouvre la fenêtre d'authentfication de l'AD B2C
        await this.browserService.openSite(redirectUrl);

        if (this.deviceService.isMobile()) {
            App.addListener('appUrlOpen', async (data: any) => {

                let token: string = data.url.split("id_token=")[1];
                let decodedToken: JwtToken;
                if (token) {
                    decodedToken = jwtDecode(token);
                }
                await this.saveUserInfos(decodedToken, token);
            });
            await this.listenBrowserClose();
        }
    }

    /**
     * Méthode qui déconnecte l'utilisateur 
     * !!! la méthode est incomplète, il faut aller invalider le token dans le b2c
     */
    async logout(): Promise<void> {
        await this.deviceService.setProperty(PreferencesEnum.userId, null);
        await this.deviceService.setProperty(PreferencesEnum.role, null);
        await this.deviceService.setProperty(PreferencesEnum.token, null);
        await this.deviceService.setProperty(PreferencesEnum.user, null);
        this.authGuard.setUser(null);
        this.authGuard.setRole(null);

        this.navigateFilActualite();
        this.toastService.createToast("Vous avez été déconnecté avec succès !")
    }

    // On attend la fermeture de la fenêtre navigateur
    async listenBrowserClose(): Promise<void> {
        Browser.addListener('browserFinished', () => {

        });
    }

    /**
     * On persiste des informations de l'utilisateur telles que son oidn son role et son token
     * @param decodedToken 
     * @param token 
     */
    async saveUserInfos(decodedToken: JwtToken, token: string): Promise<void> {
        await this.deviceService.setProperty(PreferencesEnum.userId, decodedToken.oid);
        await this.deviceService.setProperty(PreferencesEnum.role, decodedToken.extension_TerroirRoles);
        await this.authGuard.setRole(decodedToken.extension_TerroirRoles);

        this.deviceService.setProperty(PreferencesEnum.token, token).then(async () => {
            await this.authGuard.setIsLoginning(false);

            // S'il s'agit d'une création de compte on demande la saisie d'autres informations
            if (decodedToken.newUser) {
                this.openModalFirstLogin(decodedToken);
            }
            else {
                this.getUser();
            }
        });
    }

    /**
     * On stock l'utilisateur connecté de manière persistente
     * @param user 
     */
    async saveUser(user: User): Promise<void> {
        await this.deviceService.setObj(PreferencesEnum.user, user);
    }

    /**
     * Modal afin de récupérer plus d'informations lors de la première connexion
     * @param decodedToken 
     */
    async openModalFirstLogin(decodedToken: JwtToken) {
        const modal = await this.modalCtrl.create({
            component: RegisterUserModalComponent,
            componentProps: {
                decoded: decodedToken
            }
        });
        modal.present();

        const { data, role } = await modal.onWillDismiss();

        if (role === 'confirm') {
            this.createUser(data);
        }
    }

    /**
     * Sauvegarde l'utilisateur en cours dans le stockage persistant et l'observable
     * @param user
     * @returns 
     */
    async setCurrentUser(user: User): Promise<void> {
        this.authGuard.setUser(user);
    }

    /**
     * Créer l'utilisateur dans la base SQL Server avec les informations supplémentaires
     * @param user 
     */
    async createUser(user: User) {
        this.userService.create(user).subscribe({
            next: (response: User) => {
                this.saveUser(response);

                this.setCurrentUser(response).then(() => {
                    this.navigateFilActualite();
                });
            },
            error: (error: HttpErrorResponse) => {
                this.toastService.createToast("Une erreur est survenue lors de la sauvegarde de vos informations", "error");
            }
        });
    }

    navigateFilActualite() {
        this.navCtrl.navigateForward(`/fil-actualite`);
    }

    /**
     * Récupère l'utilisateur lié à ce token
     * redirige ensuite sur le fil d'actualité
     * @param user 
     */
    getUser(): Promise<void> {

        return new Promise((resolve, reject) => {
            this.userService.getByOid().subscribe({
                next: async (response: User) => {
                    await this.saveUser(response);

                    this.setCurrentUser(response).then(() => {
                        resolve();
                        this.navigateFilActualite();
                    });
                },
                // Si l'utilisateur n'a pas encore saisis ses informations complémentaires
                error: async (error: HttpErrorResponse) => {
                    if (error.status == 404) { // Si l'utilisateur n'existe pas
                        let token: string = await this.deviceService.getProperty(PreferencesEnum.token);
                        let decoded: JwtToken;
                        if (token) {
                            decoded = jwtDecode(token);
                        }

                        this.openModalFirstLogin(decoded);
                        resolve();
                    }
                }
            });
        });

    }
}